module.exports = [{
      plugin: require('../plugins/gatsby-plugin-primary-theme/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../plugins/gatsby-plugin-root-and-page-wrapper/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"disableAutoprefixing":true},
    },{
      plugin: require('../plugins/gatsby-plugin-cookie-information/gatsby-browser.js'),
      options: {"plugins":[],"defaultLanguage":"en"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Valhalla","short_name":"Valhalla","start_url":"/","background_color":"#000000","theme_color":"#ffffff","icon":"src/images/blossa/favicon.png","include_favicon":false,"display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"74677fdb615406e22ec74a59d6f171eb"},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
